import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58')
];

export const server_loads = [7,8,9,2,3,4,5,6];

export const dictionary = {
		"/": [10],
		"/admin": [39,[7]],
		"/admin/clients": [~40,[7]],
		"/admin/clients/[clientId=uuid]": [~41,[7]],
		"/admin/clients/[clientId=uuid]/dashboards/new": [~43,[7]],
		"/admin/clients/[clientId=uuid]/dashboards/[dashId=integer]": [~42,[7]],
		"/admin/db": [~44,[7]],
		"/admin/glossary": [~45,[7]],
		"/admin/glossary/new": [~47,[7]],
		"/admin/glossary/[term=integer]": [~46,[7]],
		"/admin/initiatives": [~48,[7,8]],
		"/admin/initiatives/new": [~50,[7,8]],
		"/admin/initiatives/[initId=uuid]": [~49,[7,8]],
		"/admin/risk-factors": [~51,[7]],
		"/admin/risk-factors/[id=integer]": [~52,[7]],
		"/admin/users": [~53,[7]],
		"/admin/users/[clientId=uuid]": [~54,[7,9]],
		"/admin/users/[clientId=uuid]/new": [~56,[7,9]],
		"/admin/users/[clientId=uuid]/[userId=integer]": [~55,[7,9]],
		"/(auth)/(base)/execdash": [~11,[2]],
		"/(auth)/(base)/glossary": [~12,[2]],
		"/(auth)/(base)/home": [~13,[2]],
		"/(auth)/(base)/providers": [~14,[2]],
		"/(auth)/regions": [~20,[2]],
		"/(auth)/regions/[regionId]": [~21,[2,3]],
		"/(auth)/regions/[regionId]/[unitId]": [~22,[2,3,4]],
		"/(auth)/regions/[regionId]/[unitId]/glossary": [~24,[2,3,4]],
		"/(auth)/regions/[regionId]/[unitId]/initiatives": [25,[2,3,4,5]],
		"/(auth)/regions/[regionId]/[unitId]/initiatives/new": [26,[2,3,4,5]],
		"/(auth)/regions/[regionId]/[unitId]/tasks": [~27,[2,3,4]],
		"/(auth)/regions/[regionId]/[unitId]/tasks/new/[riskId=id]": [~38,[2,3,4]],
		"/(auth)/regions/[regionId]/[unitId]/tasks/[taskId=uuid]/discuss-with-team": [~28,[2,3,4,6]],
		"/(auth)/regions/[regionId]/[unitId]/tasks/[taskId=uuid]/elaborate-barriers": [~29,[2,3,4,6]],
		"/(auth)/regions/[regionId]/[unitId]/tasks/[taskId=uuid]/is-valid-risk": [~30,[2,3,4,6]],
		"/(auth)/regions/[regionId]/[unitId]/tasks/[taskId=uuid]/meeting-planned": [31,[2,3,4,6]],
		"/(auth)/regions/[regionId]/[unitId]/tasks/[taskId=uuid]/meeting-remind": [32,[2,3,4,6]],
		"/(auth)/regions/[regionId]/[unitId]/tasks/[taskId=uuid]/plan-team-meeting": [~33,[2,3,4,6]],
		"/(auth)/regions/[regionId]/[unitId]/tasks/[taskId=uuid]/recurring-thank-you": [34,[2,3,4,6]],
		"/(auth)/regions/[regionId]/[unitId]/tasks/[taskId=uuid]/review-complete": [~35,[2,3,4,6]],
		"/(auth)/regions/[regionId]/[unitId]/tasks/[taskId=uuid]/risk-not-applicable": [~36,[2,3,4,6]],
		"/(auth)/regions/[regionId]/[unitId]/tasks/[taskId=uuid]/risk-not-controlled": [~37,[2,3,4,6]],
		"/(auth)/regions/[regionId]/[unitId]/[providerId=uuid]": [~23,[2,3,4]],
		"/(auth)/(base)/settings": [15,[2]],
		"/(auth)/(base)/settings/filters": [~16,[2]],
		"/(auth)/(base)/settings/filters/new": [~18,[2]],
		"/(auth)/(base)/settings/filters/[filterId]": [~17,[2]],
		"/(auth)/(base)/settings/notifications": [19,[2]],
		"/signin": [57],
		"/signout": [58]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';